export enum CalendarEventTypeEnum {
  WALKIN = 'Walkin',
  LAST_MINUTE = 'last_minute',
  APPOINTMENT = 'appointment',
  RESERVATION = 'reservation',
  TASK = 'task',
  BREAK = 'break',
  BLOCKER = 'blocker',

  SLOT = 'slot',
  WORKING_HOURS = 'working_hours',
}
