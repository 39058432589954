import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import './App.scss';

import { AuthWrapper, BaseLayout, LocaleProvider, StoreWrapper, UserAgentWrapper } from 'layouts';
import { Calendar, EmployeeExchange } from 'screens';

import GoogleAnalyticsWrapper from './layouts/GoogleAnalyticsWrapper';
import { Login } from './features/Login';
import { Search } from './screens/search';

function App() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <BaseLayout>
      <StoreWrapper>
        <LocaleProvider>
          <UserAgentWrapper>
            <BrowserRouter basename={process.env.REACT_APP_INDEX_FILE_PATH}>
              <GoogleAnalyticsWrapper />
              <SentryRoutes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<AuthWrapper />}>
                  <Route path="/" element={<Navigate to="/calendar" />} />
                  {/* <Route index element={<Dashboard />} /> */}
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/search" element={<Search />} />
                  <Route path="/employee-exchange" element={<EmployeeExchange />} />
                </Route>
              </SentryRoutes>
            </BrowserRouter>
          </UserAgentWrapper>
        </LocaleProvider>
      </StoreWrapper>
    </BaseLayout>
  );
}

export default App;
