import React, { useMemo } from 'react';
import { CustomEventProps } from '../types';
import { CalendarEventTypeEnum } from '../../../types';
import {
  DayEventSlot,
  DayEventWorkingHours,
  DayEventAppointment,
  DayEventBreak,
  DayEventBlocker,
  DayEventWalkin,
  DayEventReservation,
  DayEventLastMinuteBooking,
} from '../subcomponents';

const dayEventComponentByTypeMap = {
  [CalendarEventTypeEnum.WORKING_HOURS]: DayEventWorkingHours,
  [CalendarEventTypeEnum.SLOT]: DayEventSlot,
  [CalendarEventTypeEnum.APPOINTMENT]: DayEventAppointment,
  [CalendarEventTypeEnum.BREAK]: DayEventBreak,
  [CalendarEventTypeEnum.BLOCKER]: DayEventBlocker,
  [CalendarEventTypeEnum.WALKIN]: DayEventWalkin,
  [CalendarEventTypeEnum.LAST_MINUTE]: DayEventLastMinuteBooking,
  [CalendarEventTypeEnum.RESERVATION]: DayEventReservation,
};

export function DayEvent(props: CustomEventProps) {
  const { event } = props;

  const EventComponent = useMemo(() => dayEventComponentByTypeMap[event.type] || null, [event]);
  if (!EventComponent) {
    return null;
  }

  return (
    <>
      <EventComponent {...props} />
    </>
  );
}
