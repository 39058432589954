export default {
  'common.formHasErrors': 'Bitte überprüfen Sie das Formular auf fehlende Pflichtfelder',
  'common.from': 'Von',
  'common.to': 'Bis',
  'common.today': 'Heute',
  'common.more': 'mehr',
  'common.editEntity': 'Bearbeiten',
  'common.abort': 'Abbrechen',
  'common.delete': 'Löschen',
  'common.language': 'Sprache auswählen',
  'common.defaultMenuTitle': 'Termine',
  'common.signOut': 'Abmelden',
  'common.defaultStoreSettings': 'NDL Einstellungen',
  'common.save': 'Speichern',
  'common.confirm': 'Bestätigen',
  'common.error': 'Fehler',
  'common.defaultError': 'Etwas ist schief gelaufen',
  'common.defaultErrorDescription': 'Cache löschen und neu laden',
  'common.createAppointmentError': 'Beim Erstellen des Termins ist ein Fehler aufgetreten',
  'common.createAppointmentErrorDescription': 'Der Termin wurde nicht erstellt. Bitte überprüfen Sie die Internetverbindung',
  'common.deleteAppointmentError': 'Ein Fehler ist aufgetreten, als versucht wurde, den Termin zu löschen',
  'common.deleteAppointmentErrorDescription': 'Der Termin wurde nicht gelöscht. Bitte überprüfen Sie Ihre Internetverbindung',
  'common.appVersion': 'Version',
  'common.minutesShort': '{minutes} min',
  'common.hour': 'Hour',
  'common.minutes': 'Minutes',
  'common.gender.none': '-',
  'common.gender.male': 'Herr',
  'common.gender.female': 'Frau',
  'common.search': 'Suche',
  'filter.title': 'Fokus auf',
  'appointment.copyToNew': 'Daten für neuen Termin übernehmen',
  'appointment.information': 'Informationen',
  'appointment.recurringEvent': 'Wiederkehrendes Event',
  'appointment.recurringEventPlaceholder': 'Nicht wiederkehrend',
  'appointment.lastName': 'Nachname',
  'appointment.firstName': 'Vorname',
  'appointment.email': 'Email',
  'appointment.phoneNumber': 'Telefon',
  'appointment.notes': 'Notizen',
  'appointment.title': 'Titel',
  'appointment.preferredTimes': 'Bevorzugte Zeiten',
  'appointment.availableTimes': 'Verfügbare Zeiten',
  'appointment.showPreferred': 'Zeiten ausblenden',
  'appointment.showAll': 'Alle Zeiten anzeigen',
  'appointment.swapTimes': 'Mehr Optionen',
  'appointment.swapTimesDescription': 'Eine rechenintensive Prüfung des Lösungsraums kann zu weiteren Terminoptionen führen. Damit werden Termine für spezifische Mitarbeiter ermöglicht, indem Termine unter Mitarbeitern getauscht werden.',
  'appointment.swapTimesEmployeeNotSelected': 'Um mehr Optionen zu prüfen, wähle einen Mitarbeiter aus.',
  'appointment.swapTimesFetch': 'Mehr Optionen prüfen',
  'appointment.swapTimesEmpty': 'Keine weiteren Optionen verfügbar',
  'appointment.appointment': 'Termin',
  'appointment.walkin': 'Laufkunde',
  'appointment.task': 'Aufgabe',
  'appointment.break': 'Pause',
  'appointment.blocker': 'Blocker',
  'appointment.customer': 'Kunde',
  'appointment.specificEmployee': 'Spezifischer Mitarbeiter',
  'appointment.date': 'Datum',
  'appointment.service': 'Service',
  'appointment.selectAllServices': 'Alle auswählen',
  'appointment.unselectAllServices': 'Alle löschen',
  'appointment.anyAvailableEmployee': 'Jeder verfügbare Mitarbeiter',
  'language:dialogTitle': 'Sprache auswählen',
  'language.german': 'Deutsch',
  'language.english': 'English',
  'language.french': 'Français',
  'service.visionTest': 'Sehtest',
  'service.advisory': 'Beratung',
  'service.sunglasses': 'Sonnenbrille',
  'service.driverVisionTest': 'Fü.-Sehtest',
  'service.pickup': 'Abholung',
  'service.service': 'Service',
  'service.workshop': 'Werkstatt',
  'service.serviceWorkshop': 'Reparatur',
  'service.eyeCheckUp': 'Sehtest',
  'service.augen': 'Augen-Check-Up',
  'service.KLAdjustment': 'KL Versorgung',
  'service.KLFollowUpInspection': 'KL Nachkontrolle',
  'service.serviceHA': 'Service HA',
  'service.firstConsultation': '1.Beratungstermin',
  'service.shortHearingTest': 'Kurzhörtest',
  'service.followUpAppointment': 'Folgetermin',
  'service.submissionCompletion': 'Abgabe/Abschl.',
  'service.postControl': 'Nachkontrolle',
  'service.adultPostControl': 'Erw.-Nachkontr.',
  'service.earProtection': 'Gehörschutz',
  'service.orderOptimization': 'Auftragsoptimierung',
  'service.other': 'Andere',
  'service.BO': 'BO',
  'service.KL': 'KL',
  'service.HA': 'HA',
  'service.followUpFitting': 'Folge-Anpassung',
  'service.videoConsultation': 'Videoberatung',
  'service.consultation': 'Beratung',
  'menu.operations': 'Operations',
  'menu.performance': 'Performance',
  'menu.appointments': 'Termine',
  'menu.confirmEpepResyncTitle': 'Bestätigen Sie die EPEP-Synchronisierung',
  'menu.confirmEpepResyncContent': 'Bitte bestätigen Sie die Synchronisierung von EPEP',
  'menu.confirmBreaksResyncTitle': 'Bestätigen Sie die Pausen Synchronisierung',
  'menu.confirmBreaksResyncContent': 'Bitte bestätigen Sie die Synchronisierung der Pausen',
  'menu.epepSync': 'ePEP-Daten laden',
  'menu.epepSyncDescription': 'Klicken Sie hier, um kürzlich geänderte Daten aus ePEP in LTB zu laden.',
  'menu.breaksSync': 'Pausenplanung übertragen',
  'menu.breaksSyncDescription': 'Klicken Sie hier, wenn Sie die Pausenplanung in DiWa mit den LTB-Pausenwerten überschreiben möchten.',
  'calendarOverview.overflowItems': 'Konflikte',
  'calendarOverview.employees': 'Mitarbeiter',
  'calendarOverview.noEvents': 'Noch keine Termine',
  'resources.EyeTestRooms': 'Sehtesträume',
  'resources.ContactLenses': 'KL-Räume',
  'resources.Horgerate': 'HA-Räume',
  'calendar.employee': 'Mitarbeiter',
  'calendar.employeeWorkingHours': 'Arbeit {from} Bis {to}',
  'calendar.appointments': 'Termine',
  'calendar.breaks': 'Pause',
  'calendar.blockers': 'Blocker',
  'calendar.tasks': 'Aufgaben',
  'calendar.slots': 'Slots',
  'calendar.walkin': 'Laufkunden',
  'calendar.deleteAppoinctmentDialog':
    'Sind Sie sicher, dass Sie den Termin löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
  'calendar.deleteAppointmentDialogTitle': 'Löschen bestätigen',
  'calendar.walkinTitle': 'Reserviert für Laufkunden',
  'calendar.lastMinuteTitle': 'Reserviert für Last-Minute-Buchungen',
  'calendar.reserveTitle': 'Reserviert',
  'calendar.createAppointment': 'Termin hinzufügen',
  'calendar.appointmentIsDeleting': 'Termin wird gelöscht',
  'calendar.appointmentIsDeletingDescription': 'Bitte warten Sie auf die Aktualisierung',
  'calendar.submit': 'Anwenden',
  'calendar.storeNotWorking': 'Die Niederlassung ist heute geschlossen',
  'calendar.overflow': 'Überlauf',
  'search.title': 'Globale Suche',
  'search.noResults': 'Keine Ergebnisse',
  'search.noName': 'Kein Name',
  'calendarEvent.employee.noSlotsAvailableError':
    'Beim ausgewählten Mitarbeiter stehen für diesen Service keine freien Plätze zur Verfügung',
  'userAgent.notCompatibleTitle': 'Not compatible',
  'userAgent.notCompatibleDescription': 'Your current browser is not compatible with LineTweet Booking (LTB). Please use Edge',
  'userAgent.notCompatibleTip': 'Use Edge',
  'slot.slotsFetchErrorTitle': 'Fehler beim Abrufen der Slots, bitte aktualisieren',
};
